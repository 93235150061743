import React from "react";
import styled from "styled-components";
import { respondTo } from "../../styles";
import {
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";

const AboutContacts = styled.address`
  background-color: var(--infoBg);
  border-radius: 10px;
  width: 100%;
  padding: 26px;
  line-height: 26px;
  font-style: normal;

  ${respondTo.lg`
    width: 50%;
  `}
`;
const AboutPageContact = ({ aboutContact }) => {
  const {
    heading,
    phone,
    email,
    orgNumber,
    addressRecipient,
    addressStreet,
    addressZip,
    addressCity,
  } = aboutContact;
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <h2>{heading}</h2>
          <AboutContacts>
            E-post: <a href={`mailto:${email}`}>{email}</a>
            <br />
            Telefonnummer: <a href={`tel:${phone}`}>{phone}</a>
            <br />
            Postadress: <br />
            {addressRecipient}
            <br />
            {addressStreet}
            <br />
            {addressZip} {addressCity}
            <br />
            Organisationsnummer: {orgNumber}
          </AboutContacts>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
export default AboutPageContact;
