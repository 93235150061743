import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import {
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";
import { respondTo } from "../../styles";

const AboutTeamMember = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

const AboutTeamMemberText = styled.div`
  margin-bottom: 40px;

  ${respondTo.lg`
    width: 60%;
  `}
  h3 {
    margin-bottom: 16px;
  }
`;

export const AboutPageTeam = ({ aboutTeam }) => {
  const { heading, aboutTeamList } = aboutTeam;
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <h2>{heading}</h2>
          {!!aboutTeamList &&
            aboutTeamList.map(
              ({ heading, presentation, image, altText }, i) => (
                <AboutTeamMember key={i}>
                  <AboutTeamMemberText>
                    <h3>{heading}</h3>
                    <p>{presentation}</p>
                  </AboutTeamMemberText>
                  <ImageWrapperRectangle
                    mediumWidth="360px"
                    mediumHeight="auto"
                  >
                    <Img fluid={image.childImageSharp.fluid} alt={altText} />
                  </ImageWrapperRectangle>
                </AboutTeamMember>
              )
            )}
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
