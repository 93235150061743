import React from "react";
import Img from "gatsby-image";
import {
  ImageWrapperRectangle,
  PageSection,
  PageSectionBackdrop,
  PageSectionHeader,
  PageSectionHeaderText,
  TextEditor,
} from "../common";
import { toHTML } from "../../utils/helpers";
import { PageHeaderImage } from "../common/PageHeaderImage";

export const AboutPageHeader = ({ aboutHeader }) => {
  const { heading, body, image, altText } = aboutHeader;
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionHeader>
          <PageHeaderImage />
          <PageSectionHeaderText>
            <h1>{heading}</h1>
            <TextEditor dangerouslySetInnerHTML={{ __html: toHTML(body) }} />
          </PageSectionHeaderText>

          <ImageWrapperRectangle
            height="auto"
            width="620px"
            mediumWidth="360px"
            mediumHeight="auto"
          >
            {!!image && (
              <Img fluid={image.childImageSharp.fluid} alt={altText} />
            )}
          </ImageWrapperRectangle>
        </PageSectionHeader>
      </PageSectionBackdrop>
    </PageSection>
  );
};
