import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { toHTML } from "../../utils/helpers";
import {
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  TextEditor,
} from "../common";
import { respondTo } from "../../styles";
import { PageSectionHeaderText } from "../common/PageSection";

const AboutPartnersArticle = styled(PageSectionArticle)`
  ${respondTo.lg`
    display: grid;
    grid-template-columns: 320px auto;
    grid-template-rows: auto auto;
    column-gap: 100px;
    padding: 40px 0 0;

    ${ImageWrapperRectangle} {
      grid-column: 2 / 3;
    }
  `}
`;

const AboutPartnersArticleText = styled(PageSectionHeaderText)`
  h2 {
    color: black;
  }
`;

export const AboutPagePartners = ({ aboutPartners }) => {
  const { heading, body, image, altText } = aboutPartners;
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <AboutPartnersArticle>
          <AboutPartnersArticleText>
            <h2>{heading}</h2>
            <TextEditor dangerouslySetInnerHTML={{ __html: toHTML(body) }} />
          </AboutPartnersArticleText>

          <ImageWrapperRectangle
            height="auto"
            width="620px"
            mediumWidth="360px"
            mediumHeight="auto"
          >
            {!!image && (
              <Img fluid={image.childImageSharp.fluid} altText={altText} />
            )}
          </ImageWrapperRectangle>
        </AboutPartnersArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
