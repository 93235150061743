import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageSectionWrapper } from "../components/common";
import {
  AboutPageHeader,
  AboutPagePartners,
  AboutPageTeam,
} from "../components/AboutPage";
import AboutPageContact from "../components/AboutPage/AboutPageContact";

const AboutPageTemplate = ({
  title,
  aboutHeader,
  aboutPartners,
  aboutTeam,
  aboutContact,
}) => {
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper>
        <AboutPageHeader aboutHeader={aboutHeader} />
        <AboutPagePartners aboutPartners={aboutPartners} />
        <AboutPageTeam aboutTeam={aboutTeam} />
        <AboutPageContact aboutContact={aboutContact} />
      </PageSectionWrapper>
    </>
  );
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <AboutPageTemplate
      title={frontmatter.title}
      aboutHeader={frontmatter.aboutHeader}
      aboutPartners={frontmatter.aboutPartners}
      aboutTeam={frontmatter.aboutTeam}
      aboutContact={frontmatter.aboutContact}
    />
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        aboutHeader {
          heading
          body
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
        }
        aboutPartners {
          heading
          body
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
        }
        aboutTeam {
          heading
          aboutTeamList {
            heading
            presentation
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
        }
        aboutContact {
          heading
          phone
          email
          orgNumber
          addressRecipient
          addressStreet
          addressZip
          addressCity
        }
      }
    }
  }
`;
